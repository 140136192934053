/**
 * Created by Administrator on 2017/6/22.
 */
class Components{

    constructor(){

        this.size=true;//防止pc端重复请求手机端头部。
        this.load();

    }

    load(){
        let ua = navigator.userAgent;
        let ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
            isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
            isAndroid = ua.match(/(Android)\s+([\d.]+)/),
            isMobile = isIphone || isAndroid||ipad;

        //判断
        if(isMobile) {
            $(window).load(()=>{
                $('.wap_nav').load("header_mobile.html",function (){
                    window.wh=$(this).outerHeight();
                    Components.mobileThreeNavClick();
                    Components.mobileNavName();
                    Components.mobileIndex();
                });
                $('.footer_wrapper').load("footer.html",function () {
                    Components.year();
                    window.h=$(this).outerHeight();
                });
                setTimeout(function () {
                  let l= $(window).height();
                      $('.pageLoading-a').fadeOut();
                      $('.all_main').css({opacity:1});
                      $(".container_wrapper").css({"min-height":l-h-wh});
                },1000);
                $(".la-ball-clip-rotate").removeClass("la-2x");
                this.nav();
                this.wapMenuClickIndex();
                this.secondMenu();
                this.threeMenu();
                this.scroll();
                this.returnTop();
                Components.websiteSeo();
            })
        }else {
            $(window).load(()=>{
                $('.header_wrapper').load("header_pc.html",function () {
                    setTimeout(function () {
                        Components.pcIndex();
                    },200);
                    Components.pcThreeNavClick();
                    Components.pcNavImgRequest();
                    Components.pcNavName();
                });
                $('.footer_wrapper').load("footer.html",function () {
                    Components.year();
                    window.h=$(this).outerHeight();
                });
                this.nav();
                this.mobileNav();
                this.pcMenuClickIndex();
                this.resize();
                this.scroll();
                this.returnTop();
                Components.websiteSeo();
                $('.pageLoading-a').fadeOut();
                $('.all_main').css({opacity:1});
            })
        }

    }

    nav(){
        //pc菜单
        $('.header_wrapper').on('mouseenter','.second_nav_item',function(){
                  let f=$(this).parents('.hide_nav_main').parent().index();
                  let i=$(this).index();
                  $('.first_nav_item').eq(f).find('.img_box').eq(i).show().siblings().hide()
        });

        //mobile菜单
        $(".wap_nav").on('click',".menu",function(){

            if($(this).parent().hasClass('open')){
                $(this).parent().removeClass('open');
                $('.all_main').removeClass('open');
            }else{
                $(this).parent().addClass('open');
                $('.all_main').addClass('open');
            }
        });

    }

    mobileNav(){
        //移动端菜单
        let w=$(window).width(),
            nav=$(".wap_nav");

        if(w<=768&&this.size){
            this.size=false;
            this.wapMenuClickIndex();
            this.secondMenu();
            this.threeMenu();
            nav.load('header_mobile.html',function () {
                    window.wh=$(this).outerHeight();
                    Components.mobileThreeNavClick();
                    Components.mobileNavName();
                    Components.mobileIndex();
                });
            $(".la-ball-clip-rotate").removeClass("la-2x");
        }
    }

    secondMenu(){
        //二级菜单组件
        $(".wap_nav").on('click','.first_level_item>a',function(){
            $(this)
                .parent()
                .addClass('hover_link')
                .find('.second_level')
                .stop(false,true)
                .slideToggle(function () {$('.three_level').stop(false,true).slideUp()})
                .parent()
                .siblings()
                .removeClass('hover_link')
                .find('.second_level')
                .stop(false,true)
                .slideUp()
        });
    }

    threeMenu(){
        //三级菜单组件
        $(".wap_nav").on('click','.second_level_item>a',function(){
            $(this)
                .parent()
                .addClass('active')
                .find('.three_level')
                .stop(false,true)
                .slideToggle()
                .parent()
                .siblings()
                .removeClass('active')
                .find('.three_level')
                .stop(false,true)
                .slideUp()
        });
    }

    pcMenuClickIndex(){
        //pc菜单点击索引值记录
        let header=  $(".header_wrapper");

        header.on("click",".first_nav_item",function (e) {
            e.stopPropagation();//阻止冒泡
            let f;
            f=$(this).index();
            sessionStorage.setItem('f',f);

        });
        header.on('mouseenter','.second_nav_item',function(e){
            e.stopPropagation();//阻止冒泡
            let f,s;
                f=$(this).parents(".first_nav_item").index();
                s=$(this).index();
                sessionStorage.setItem('f',f);
                sessionStorage.setItem('s',s);

        });

        header.on('click','.three_nav_item',function(e){
            e.stopPropagation();//阻止冒泡
            let t;
            t=$(this).index();
            sessionStorage.setItem('t',t);
        });

    }

    wapMenuClickIndex(){
        //mobile菜单点击索引值记录
        $(".wap_nav").on('click','li',function(e){
            e.stopPropagation();//阻止冒泡
            let f,s,t;
            if($(this).hasClass('first_level_item')){
                f=$(this).index();
                sessionStorage.setItem('f',f);
            }else if($(this).hasClass('second_level_item')){
                s=$(this).index();
                sessionStorage.setItem('s',s);
            }else{
                t=$(this).index();
                sessionStorage.setItem('t',t);
            }
        });
    }

    resize(){
        $(window).resize(()=>{

            this.mobileNav("pc")
        })

    }

    scroll(){

        $(window).scroll(function () {

                let sh= $(this).scrollTop(),
                    wh=$(this).height(),
                    topReturn=$(".top_return"),
                    header=$(".header_wrapper");
                if(sh>wh){
                    topReturn.fadeIn();
                }else{
                    topReturn.fadeOut();
                }

                if(sh>=73){
                    header.css({marginTop:-73+"px"})
                }else{
                    header.css({marginTop:0})
                }
        })
    }

    returnTop(){

        $(".top_return").click(function () {
            $('html,body').animate({'scrollTop':0},800);
        })
    }

    static pcNavImgRequest(){
        Yfd.ajax.getMultiRecord({categoryId:92},function(res){

               res.data.forEach(function (v,i) {
                   $("#nav_1").find("img").eq(i).attr("src",Yfd.rootdir+res.data[i].img_url);
               });
        });
        Yfd.ajax.getMultiRecord({categoryId:93},function(res){
            res.data.forEach(function (v,i) {
                $("#nav_2").find("img").eq(i).attr("src",Yfd.rootdir+res.data[i].img_url);
            });
        });
        Yfd.ajax.getMultiRecord({categoryId:94},function(res){
            res.data.forEach(function (v,i) {
                $("#nav_3").find("img").eq(i).attr("src",Yfd.rootdir+res.data[i].img_url);
            });
        });
        Yfd.ajax.getMultiRecord({categoryId:96},function(res){
            res.data.forEach(function (v,i) {
                $("#nav_4").find("img").eq(i).attr("src",Yfd.rootdir+res.data[i].img_url);
            });
        });
    }

    static pcNavName(){
        Yfd.ajax.getCategory(16,function (res) {

            var str="";
            res.data.forEach(function (v) {

                str+='<li class="three_nav_item">'+
                    '<a href="ad_campaign.html" data-id='+v.id+'>'+
                    '<span class="en">'+v.title_en+'</span>'+
                    '<span class="cn">'+v.title+'</span>'+
                    '</a>'+
                    '</li>';
            });
            $("#nav_child_1").append(str);
        });
        Yfd.ajax.getCategory(67,function (res) {
            var str="";
            res.data.forEach(function (v) {
                str+='<li class="three_nav_item">'+
                    '<a href="look_book.html" data-id='+v.id+'>'+
                    '<span class="en">'+v.title_en+'</span>'+
                    '<span class="cn">'+v.title+'</span>'+
                    '</a>'+
                    '</li>';
            });
            $("#nav_child_2").append(str);
        });
    }

    static pcThreeNavClick(){

        $("#s_nav_1").on("click","a",function () {
            var id= $(this).attr("data-id");
            sessionStorage.setItem("id",id);
        });

        $("#s_nav_2").on("click","a",function () {
            var id= $(this).attr("data-id");
            sessionStorage.setItem("id",id);
        });
        
        $("#nav_child_1").on("click","a",function () {
            var id= $(this).attr("data-id");
            sessionStorage.setItem("id",id);
        });

        $("#nav_child_2").on("click","a",function () {
            var id= $(this).attr("data-id");
            sessionStorage.setItem("id",id);
        })
    }

    static mobileNavName(){
        Yfd.ajax.getCategory(16,function (res) {

            var str="";
            res.data.forEach(function (v) {

                str+='<li class="three_level_item">'+
                    '<a class="ad" href="ad_campaign.html" data-id='+v.id+'>'+
                    '<span class="en" style="padding-right:5px">'+v.title_en+'</span>'+
                    '<span class="cn">'+v.title+'</span>'+
                    '</a>'+
                    '</li>';
            });
            console.log(res.data)

            $("#nav_mobile_1").append(str);
        });
        Yfd.ajax.getCategory(67,function (res) {
            var str="";
            res.data.forEach(function (v) {
                str+='<li class="three_level_item">'+
                    '<a class="look" href="look_book.html" data-id='+v.id+'>'+
                    '<span class="en" style="padding-right:5px">'+v.title_en+'</span>'+
                    '<span class="cn">'+v.title+'</span>'+
                    '</a>'+
                    '</li>';
            });
            $("#nav_mobile_2").append(str);
        });
    }

    static mobileThreeNavClick(){

        $(".wap_nav").on("click",".ad",function () {
            var id= $(this).attr("data-id");
            sessionStorage.setItem("id",id);

        }).on("click",".look",function () {
            var id= $(this).attr("data-id");
            sessionStorage.setItem("id",id);
        })
    }

    static websiteSeo(){

        Yfd.ajax.getWebsiteSeo(function (res) {
            $('meta[name="keywords"]').attr("content",res.data[0].keywords);
            $('meta[name="description"]').attr("content",res.data[0].description);
            $('meta[name="author"]').attr("content",res.data[0].seo_title);
        })
    }

    static pcIndex(){
        //pc端索引值读取
        let f ,s ,t,query;
         f= sessionStorage.getItem('f');
         s= sessionStorage.getItem('s');
         t= sessionStorage.getItem('t');
            sessionStorage.clear();
            query=$(".first_nav").children().eq(f);
         if(f!==null){
             query.addClass('navOn');
             if(s!==null){
                 query.find(".second_nav").children().eq(s).addClass('navOn');
                 if(t!==null){
                     query.find(".second_nav").children().eq(s).find(".three_nav").children().eq(t).addClass("navOn");
                 }
             }

         }

    }

    static mobileIndex(){
        //mobile端索引值读取
        let f,s,t;
        f=sessionStorage.getItem('f');
        s=sessionStorage.getItem('s');
        t=sessionStorage.getItem('t');
          sessionStorage.clear();
        if(f!==null) {
            let mobileMenu=$(".first_level").children().eq(f);
                mobileMenu.addClass('hover_link');
            if ( s !== null) {
                let query = mobileMenu.find('.second_level').children().eq(s);
                    query.addClass('active');
                if ((t !== null)) {
                    query.find('.three_level').children().eq(t).addClass('active')
                }
            }
        }
    }

    static year() {
        //底部版权年份同步系统年份
        let currentTime=new Date();
        let currentYear=currentTime.getFullYear();
        $('#time').html(currentYear);
    }

}

new Components();
